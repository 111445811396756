<template>
  <BaseLoading v-if="loading" />
  <div class="container wrapper" ref="scrollbox" v-else>
    <div class="content">
      <BaseTop :cShowWork="isShowWork" @changeShowWork="pChangeShowWork" />
      <!-- 内容 begin -->
      <div class="con">
        <div class="mask" v-if="!hasLogin" @click="handleHasLogin"></div>
        <h2 class="title ellipsis">游戏教学 寓教于乐</h2>
        <div class="game-item">
          <div class="infos-pic">
            <img :src="preSrc + infos.game_pic" alt="" />
          </div>
          <ul class="flex">
            <li
              v-for="(item, index) in infos.game"
              :key="index"
              @click.prevent="toGameInfo(item.game_id)"
            >
              <h3 class="ellipsis" :title="item.name">{{ item.name }}</h3>
              <p class="desc">{{ item.desc }}</p>
              <p class="flex detail">
                查看详情 <span class="iconfont icon-jiantouyou"></span>
              </p>
              <img :src="preSrc + item.cover" alt="" />
            </li>
          </ul>
          <button class="btn more-btn" @click="toGame">
            查看更多
            <span class="iconfont icon-jiantouyou"></span>
            <span class="iconfont icon-jiantouyou"></span>
          </button>
        </div>
        <h2 class="title ellipsis">趣味课件 学习更快</h2>
        <div class="con-item course-item">
          <div class="infos-pic">
            <img :src="preSrc + infos.course_pic" alt="" />
          </div>
          <ul class="flex">
            <li
              v-for="(item, index) in infos.course"
              :key="index"
              :style="{
                background:
                  'url(' + preSrc + item.cover + ') center center no-repeat',
                backgroundSize: 'contain',
              }"
              @click.prevent="toCourseInfos(item.course_id)"
            >
              <p class="ellipsis">{{ item.name }}</p>
            </li>
          </ul>
        </div>
        <button class="btn more-btn" @click="toCourse">
          查看更多
          <span class="iconfont icon-jiantouyou"></span>
          <span class="iconfont icon-jiantouyou"></span>
        </button>
      </div>
    </div>
    <!-- 内容 end -->
    <BaseCopyright />
    <!-- 工作台 end -->
    <!-- 提示用户登录 -->
    <transition name="fade">
      <div
        class="mask-container"
        :class="[isShowLoginMask ? 'mask-show' : 'mask-hide']"
        v-if="isShowLoginMask"
      >
        <div class="mask-bg" @click="hideMask"></div>
        <div class="mask-con">
          <p class="title">提示</p>
          <p class="tips">系统检测到您还未登录，请先登录，登录后可查看</p>
          <button class="tip-btn btn" @click="hideMask">登录</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseTop from "@/components/top/top.vue";
import BaseLoading from "@/components/loading/BaseLoading.vue";
import { handlePreview } from "@/tools/GlobalFunction";
import axios2 from "@/axios2";
export default {
  name: "Home",
  components: {
    BaseTop,
    BaseLoading,
  },
  data() {
    return {
      resize: true,
      preSrc: config.src,
      infos: {},
      workOpen: false, //工作台是否展开
      isShowWork: false, //是否展示工作台
      isShowMask: false,
      workLen: false, //工作台的长度是否大于0
      loading: true,
      hasLogin: true, //是否登录
      isShowLoginMask: false,
    };
  },
  created() {
    let _self = this;
    _self.initData();
    const token = localStorage.getItem("token");
    token ? (this.hasLogin = true) : (this.hasLogin = false);
  },
  methods: {
    handleHasLogin() {
      let _self = this;
      _self.isShowLoginMask = true;
    },
    hideMask() {
      let _self = this;
      _self.isShowLoginMask = false;
      this.$refs.scrollbox.scrollTop = 0;
    },
    pChangeShowWork(params) {
      this.isShowWork = params;
    },
    // 初始化
    initData() {
      let _self = this;
      let token = localStorage.getItem("token");
      token ? (_self.isShowWork = true) : (_self.isShowWork = false);
      axios2.post(config.url.home).then((res) => {
        if (res.code == 200) {
          _self.infos = res.data;
          _self.loading = false;
          if (_self.infos.workbench.length > 0) {
            _self.workLen = true;
          }
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
        }
      });
    },

    // 修改图标
    handleIpt(idx) {
      // let _self = this;
      // _self.icon = [0, 0, 0];
      // _self.$set(_self.icon, idx, 1);
    },
    // 鼠标移入工作台
    handleMouseEnter() {
      this.workOpen = true;
    },
    // 鼠标移出工作台
    handleMouseLeave() {
      this.workOpen = false;
    },
    //游戏详情
    toGameInfo(id) {
      let _self = this;
      _self.$router.push({
        name: "game-info",
        params: {
          id: id,
        },
      });
    },
    // 到详情
    toCourseInfos(id) {
      let _self = this;
      _self.$router.push({
        name: "course-info",
        params: {
          courseId: id,
        },
      });
    },

    toCourse() {
      this.$parent.toPages("/course");
    },
    toGame() {
      this.$parent.toPages("/games");
    },
    toPages(id, type, value, title) {
      let _self = this;
      if (type == 0) {
        if (id == 1) {
          _self.$router.push({
            name: "base-game1",
            params: {
              gameId: id,
              configId: value,
              title: title,
            },
          });
        }
        if (id == 2) {
          _self.$router.push({
            name: "base-game2",
            params: {
              gameId: id,
              configId: value,
              title: title,
            },
          });
        }
        if (id == 3) {
          _self.$router.push({
            name: "base-game3",
            params: {
              gameId: id,
              configId: value,
              title: title,
            },
          });
        }
        if (id == 4) {
          _self.$router.push({
            name: "base-game4",
            params: {
              gameId: id,
              configId: value,
              title: title,
            },
          });
        }
      }
      if (type == 1) {
        _self.$router.push({
          name: "course-info",
          params: {
            courseId: value,
          },
        });
      }
      if (type == 2) {
        _self.$router.push({
          name: "level-info",
          params: {
            levelId: value,
          },
        });
      }
      if (type == 3) {
        handlePreview(value);
      }
    },
    // 工作台
    toWork() {
      let _self = this;
      _self.$router.push({
        path: "/profile/workplace",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-jiantouyou {
  margin: 4px 0 0 8px;
}
.con {
  position: relative;
  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
  .title {
    margin: 50px auto;
    text-align: center;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title::before {
    content: "";
    display: block;
    background: url("../assets/img/home/pre-icon.png") center center no-repeat;
    background-size: 100%;
    width: 36px;
    height: 32px;
    margin-right: 20px;
  }
  .infos-pic {
    width: 310px;
    height: 440px;
    overflow: hidden;
    margin-right: 25px;
    flex-shrink: 0;
    flex-grow: 0;
    img {
      width: 310px;
      height: 440px;
      object-fit: contain;
    }
  }
  .game-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      width: 275px;
      height: 400px;
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
      padding: 20px 25px;
      box-sizing: border-box;
      margin-right: 20px;
      border-radius: $border-radius-main;
      transition: 0.5s ease;
      position: relative;
      h3 {
        font-size: 24px;
        margin: 10px auto 20px;
      }
      .desc {
        color: $font-color-81;
        font-size: $font-size-main;
        height: 85px;
        line-height: 30px;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; //行数
        -webkit-box-orient: vertical;
      }
      img {
        width: 160px;
        height: 160px;
        object-fit: contain;
        margin: 30px auto 0;
      }
      .detail {
        margin-top: 10px;
        color: #ff3937;
      }
    }

    li:last-of-type {
      margin-right: 0;
    }
    li:hover {
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      transition: 0.5s ease;
      margin-top: -20px;
      // transform: translateY(-20px);

      h3 {
        color: $color-main;
      }
    }
  }
  .course-item {
    display: flex;
    align-items: flex-start;
    position: relative;

    ul {
      width: 865px;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: wrap;
    }
    li {
      width: 275px;
      height: 187px;
      overflow: hidden;
      position: relative;
      margin: 0 20px 20px 0;
      border-radius: $border-radius-main;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      transition: 0.4s ease-in-out;
      p {
        font-size: 16px;
        z-index: 3;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 13px 20px;
        width: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        border-radius: 0 0 10px 10px;
      }
    }

    li:nth-child(3),
    li:last-of-type {
      margin-right: 0;
    }
    li:hover {
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
      transition: 0.4s;
      margin-top: -20px;
      cursor: pointer;
    }
  }
  .more-btn {
    width: 300px;
    height: 60px;
    line-height: 60px;
    margin: 30px auto 0;
    span {
      margin-right: -16px;
    }
  }
}
.work {
  width: 200px;
  height: 66px;
  background: $color-main;
  border-radius: 50px;
  position: fixed;
  right: 24px;
  top: 52%;
  z-index: 5;
  img {
    width: 90px;
    height: 90px;
    border-radius: 60%;
    position: relative;
    top: -17px;
    left: -30px;
  }
  span {
    position: absolute;
    top: 11px;
    left: 39%;
    color: #fff;
    font-size: 30px;
  }
}
.work-open {
  position: fixed;
  right: 94px;
  top: 54.4%;
  z-index: 5;
  width: 230px;
  max-height: 395px;
  background: $color-main;
  border-radius: 30px;
  padding-bottom: 20px;
  img {
    width: 90px;
    height: 90px;
    border-radius: 60%;
    position: relative;
    top: -40px;
    left: 70px;
  }
  h3 {
    width: 100%;
    color: #fff;
    font-size: 30px;
    text-align: center;
    margin-top: -28px;
    margin-bottom: 20px;
  }
  li {
    width: 160px;
    margin: 0 auto 15px;
    background: #fff;
    border-radius: 20px;
    padding: 6px 20px;
    box-sizing: border-box;
    color: $color-main;
    cursor: pointer;
    span {
      display: inline-block;
      width: 96px;
      margin-left: 10px;
    }
    .iconfont.icon-kejian1,
    .iconfont.icon-kejian1 {
      font-size: 14px;
    }
    .iconfont.icon-bianzubeifen {
      font-size: 15px;
    }
  }
  .more {
    width: 60px;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
    .icon-jiantouyou {
      margin: 4px 0 0 2px;
    }
  }
}
// 弹窗
.mask-container {
  .title {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }
  .mask-bg {
    background: rgba(0, 0, 0, 0.5);
  }
  .mask-con {
    padding: 30px;
    box-sizing: border-box;
    border-radius: $border-radius-main;
    .tips {
      margin: 60px auto 70px;
      color: $font-color-51;
      font-size: 20px;
      text-align: center;
    }
    .tips::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/img/common/warn.png") center center no-repeat;
      background-size: 100%;
      margin-right: 10px;
    }
    .tip-btn {
      width: 260px;
      margin: 0 auto;
    }
  }
}
</style>
